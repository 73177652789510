import React, { forwardRef, useImperativeHandle, useState, useCallback } from 'react'
import { useTrail, useSpring, useTransition, animated } from 'react-spring'
import './Projects.css'
import './ProjectsResponsive.css'

import sosguardian from '../../assets/product_sosguardian.png'
import sosdanger from '../../assets/product_sosdanger.png'
import houseview from '../../assets/product_houseview.png'

import sosguardian_logo from '../../assets/product_sosguardian_logo.png'
import sosdanger_logo from '../../assets/product_sosdanger_logo.png'
import houseview_logo from '../../assets/product_houseview_logo.png'

const Projects = forwardRef((props, ref) => {

  const projects = [
    {
      name: 'SOS Guardian',
      logo: <LogoSOSGuardian />,
      selected: true,
      bullets: [
        <div className="flex row">
          <span>Aplicativo voltado para segurança;</span>
        </div>,

        <div className="flex row">
          <span>Hardware própio, pequeno e silêncioso. Capaz de emitir diversos tipos de alerta, de acordo com cada situação;</span>
        </div>,

        <div className="flex row">
          <span>Alerte a rede de usuários pré definida de forma rápida e discreta, para que entendam a situação e não sejam pegos desprevinidos;</span>
        </div>,

        <div className="flex column margin-top-24">
          <div className="flex row">
            <span>Saiba mais</span>
          </div>

          <div className="flex row margin-top-4">
            <a href="http://sosguardian.com.br" target="_blank" rel="noopener noreferrer" className="button">Acesse nosso site</a>
            <a href="https://www.youtube.com/watch?v=WLueVKauJhc" target="_blank" rel="noopener noreferrer" className="button margin-left-8">Vídeo</a>
          </div>
        </div>
      ],
      page: ({ style }) => (
        <animated.div style={{ ...style }} className="flex column justify-center align-center">
          <img src={sosguardian} alt="" className="project-page" />
        </animated.div>
      )
    },

    {
      name: 'SOS Danger',
      logo: <LogoSOSDanger />,
      selected: false,
      bullets: [
        <div className="flex row">
          <span>Alerta, prevenção e monitoramento de desastres naturais;</span>
        </div>,

        <div className="flex row">
          <span>Monitoramento de rios em tempo real, através de sensores e configurações de nível de perigo;</span>
        </div>,

        <div className="flex row">
          <span>Emissão de alertas para usuários do aplicativo e dashboard de acompanhamento de evolução.</span>
        </div>,

        <div className="flex column margin-top-24">
          <div className="flex row">
            <span>Saiba mais</span>
          </div>

          <div className="flex row margin-top-4">
            <a href="http://sosguardian.com.br" target="_blank" rel="noopener noreferrer" className="button">Acesse nosso site</a>
            <a href="https://www.youtube.com/watch?v=GkHUvDOHyXI" target="_blank" rel="noopener noreferrer" className="button margin-left-8">Vídeo - Sensor</a>
            <a href="https://www.youtube.com/watch?v=B5R-oTadzL8" target="_blank" rel="noopener noreferrer" className="button margin-left-8">Vídeo - Dashboard</a>
          </div>
        </div>
      ],
      page: ({ style }) => (
        <animated.div style={{ ...style }} className="flex column justify-center align-center">
          <img src={sosdanger} alt="" className="project-page" />
        </animated.div>
      )
    },

    {
      name: 'HouseView',
      logo: <LogoHouseview />,
      selected: false,
      bullets: [
        <div className="flex row">
          <span>Serviço de captura de imagens e vídeos em 360 graus;</span>
        </div>,

        <div className="flex row">
          <span>Crie sozinho seu tour virtual;</span>
        </div>,

        <div className="flex row">
          <span>Utilize nosso software para inserir setas, pins informativos, links e mapas.</span>
        </div>,

        <div className="flex column margin-top-24">
          <div className="flex row">
            <span>Saiba mais</span>
          </div>

          <div className="flex row margin-top-4">
            <a href="https://www.youtube.com/watch?v=JpvkyF-XQaw" target="_blank" rel="noopener noreferrer" className="button">Vídeo</a>
          </div>
        </div>
      ],
      page: ({ style }) => (
        <animated.div style={{ ...style }} className="flex column justify-center align-center">
          <img src={houseview} alt="" className="project-page" />
        </animated.div>
      )
    }
  ]

  const [index, setIndex] = useState(0)

  useImperativeHandle(ref, () => ({

    changeDescription

  }))

  const setProject = useCallback(i => {
    changeDescription(i)
    setIndex(state => state = i)
  }, [])

  const transitions = useTransition(index, p => p, {
    from: { opacity: 0, transform: 'translate3d(100vw, 0, 0)' },
    enter: { opacity: 1, transform: 'translate3d(0, 0, 0)' },
    leave: { opacity: 0, transform: 'translate3d(-20vw, 0, 0)' },
  });

  const defaultProject = projects[0]
  const config = { mass: 5, tension: 2000, friction: 200 }
  const [showText, setShowText] = useState(true)
  const [title, setTitle] = useState(defaultProject.name)
  const [bullets, setBullets] = useState(defaultProject.bullets)

  const trailTitle = useTrail(1, {
    config,
    opacity: showText ? 1 : 0,
    x: showText ? 0 : 20,
    height: showText ? 50 : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })

  const trailBullets = useTrail(bullets.length, {
    config,
    opacity: showText ? 1 : 0,
    x: showText ? 0 : 20,
    height: showText ? 'auto' : 0,
    from: { opacity: 0, x: 20, height: 0 },
  })

  const changeDescription = i => {
    setShowText(false)
    setTimeout(() => {
      const project = { ...projects[i] }
      const { name, bullets } = project

      setTitle(name)
      setBullets(bullets)
      setShowText(true)
    }, 700)
  }

  return (
    <div className="page">

      <div className="flex flex-3 row description-container">
        <div className="flex flex-1 column">
          <div className="flex row">
            <div className="wrapper flex column">
              <h1 className="title">Conheça alguns</h1>
              <h1 className="title">de nossos projetos.</h1>

              <div className="flex row">
                <h1 className="title">Como o </h1>

                <span style={{ marginLeft: 8 }}>
                  {trailTitle.map(({ x, height, ...rest }, index) => (
                    <animated.div
                      key={`title_${index}}`}
                      style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}>

                      <animated.h1 className="project-name">{title}</animated.h1>

                    </animated.div>
                  ))}
                </span>
              </div>
            </div>
          </div>

          <div style={{ height: 40 }}></div>

          <div className="flex column flex-1">
            {trailBullets.map(({ x, height, ...rest }, index) => (
              <animated.div
                key={`bullet_${index}`}
                className="project-bullet"
                style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }}>

                <animated.div style={{ height }}>{bullets[index]}</animated.div>

              </animated.div>
            ))}
          </div>
        </div>
      </div>


      <div className="project-container flex flex-2 row justify-center align-center simple-trans-main relative">
        {/*transitions.map(({ item, props, key }) => {
          const Page = projects[item].page
          return <Page key={key} style={props} />
        })*/}

        {trailTitle.map(({ x, height, ...rest }, i) => {
          const Page = projects[index].page
          return <Page key={`page_${i}`} style={{ ...rest, transform: x.interpolate(x => `translate3d(0,${x}px,0)`) }} />
        })}

        <div className="project-buttons">
          {projects.map((project, i) => (
            <div key={`button_${i}`} onClick={() => { setProject(i) }}>
              {project.logo}
            </div>
          ))}
        </div>
      </div>

    </div>
  )

})

function LogoSOSGuardian() {
  const calc = (x, y) => [-(y - window.innerHeight / 2) / 25, (x - window.innerWidth / 2) / 25, 1.5]
  const trans = (x, y, s) => `perspective(250px) rotateX(${x}deg) rotateY(-${y}deg) scale(${s})`
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 2, tension: 400, friction: 40 } }))

  return (
    <animated.img
      src={sosguardian_logo}
      className="pointer logo-button"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
    />
  )
}

function LogoSOSDanger() {
  const calc = (x, y) => [-(y - window.innerHeight / 2) / 25, (x - window.innerWidth / 2) / 25, 1.5]
  const trans = (x, y, s) => `perspective(250px) rotateX(${x}deg) rotateY(-${y}deg) scale(${s})`
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 2, tension: 400, friction: 40 } }))

  return (
    <animated.img
      src={sosdanger_logo}
      className="pointer logo-button"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
    />
  )
}

function LogoHouseview() {
  const calc = (x, y) => [-(y - window.innerHeight / 2) / 25, (x - window.innerWidth / 2) / 25, 1.5]
  const trans = (x, y, s) => `perspective(250px) rotateX(${x}deg) rotateY(-${y}deg) scale(${s})`
  const [props, set] = useSpring(() => ({ xys: [0, 0, 1], config: { mass: 2, tension: 400, friction: 40 } }))

  return (
    <animated.img
      src={houseview_logo}
      className="pointer logo-button"
      onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
      onMouseLeave={() => set({ xys: [0, 0, 1] })}
      style={{ transform: props.xys.interpolate(trans) }}
    />
  )
}

export default Projects