import React, { forwardRef, useState, useEffect, useImperativeHandle } from 'react'
import { SpeechBubbleContainer, SpeechBubble } from '../SpeechBubble'
import * as lottie from 'lottie-web'

import phone from '../../assets/bg_whatsapp.png'
import send from '../../assets/send.svg'
import sendMessageDone from '../../assets/animations/send-message-done.json'

import './Contact.css'
import './ContactResponsive.css'

const Contact = forwardRef((props, ref) => {

  const [messages, addMessage] = useState([])
  const [messageForm, setMessageForm] = useState({});
  const [message, setMessage] = useState('')
  const [phoneSize, setPhoneSize] = useState({ width: 0, height: 0 })
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      calcPhoneSize()
    }, 500)
  }, [
    document.querySelector('.phone-placeholder')
  ])

  useImperativeHandle(ref, () => ({

    async sendMessages() {
      const toSend = [
        { date: new Date(), arrow: true, text: 'Alguma dúvida?' },
        { date: new Date(), arrow: false, text: 'Pode nos mandar uma mensagem, que entraremos em contato.' },
        { date: new Date(), arrow: false, text: 'Vamos adorar tirar seu projeto do papel!' }
      ]

      setLoading(true)

      await delay(500)
      addMessage(messages => [...messages, toSend[0]]);

      await delay(1000)
      addMessage(messages => [...messages, toSend[1]]);

      await delay(1000)
      addMessage(messages => [...messages, toSend[2]]);

      setLoading(false)
    }

  }));

  const calcPhoneSize = () => {
    const placeholder = document.querySelector('.phone-placeholder')
    const { width, height } = placeholder

    if (width && height)
      setPhoneSize(phoneSize => { return { width, height } });
  }

  const delay = milliseconds => {
    return new Promise((resolve, reject) => {
      setTimeout(() => { resolve(true) }, milliseconds)
    })
  }

  const sendMessage = () => {
    if (loading || !messageForm.message)
      return false

    const input = document.querySelector('input[name="message"]')
    input.value = ''
    const wait = 2000

    fetch('/contact', {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ platform: 'appics.dev', message: messageForm.message })
    });

    setLoading(true)
    setMessage(messageForm.message)
    setMessageForm(messageForm => messageForm.message = '')

    setTimeout(() => {
      const className = 'opacity-0'
      const message = document.querySelector('.message')

      message.classList.add(className)

      const anim = lottie.loadAnimation({
        container: document.querySelector('.lottie'),
        renderer: 'svg',
        loop: false,
        autoplay: true,
        animationData: sendMessageDone,
      })

      setTimeout(() => {
        setMessage('')
        message.classList.remove(className)
        anim.destroy()
        setLoading(false)
      }, wait)
    }, wait)
  }

  const handleChange = event => {
    const data = { ...messageForm }
    data[event.target.name] = event.target.value

    setMessageForm(data)
  };

  const handleKeyUp = event => {
    if (event.keyCode === 13)
      sendMessage()
  }

  return (
    <div className="contact">

      <div className="left">

        <div className="flex row">
          <span className="page-title">Entre em contato!!</span>
        </div>

        <div className="flex row">
          <span className="title">21 9727-99769</span>
        </div>

        <div className="flex row">
          <span className="title">contato@appics.dev</span>
        </div>

      </div>

      <div className="right hide-pt-xs hide-ls-xs hide-ls-sm">

        <img src={phone} alt="" className="phone-placeholder" />

        <div style={{ width: phoneSize.width, height: phoneSize.height }} className="flex column phone-container justify-center align-center">

          <div className="flex column safe-area">
            <div className="flex flex-1 column">

              <SpeechBubbleContainer>
                {messages.map(message => (
                  <SpeechBubble type="left" arrow={message.arrow} text={message.text} />
                ))}

                {
                  message
                    ? <SpeechBubble type="right" arrow={true} text={message} className="message" />
                    : <></>
                }

                <div className="flex row justify-center margin-top-8">
                  <div
                    className="lottie"
                    style={{ width: '50%', height: 'auto' }}
                    data-anim-loop="true"></div>
                </div>
              </SpeechBubbleContainer>

            </div>

            <div className="flex row">
              <input
                type="text"
                name="message"
                placeholder="Digite uma mensagem"
                className="flex-1"
                disabled={loading}
                onChange={handleChange}
                onKeyUp={handleKeyUp} />

              <div className="flex column justify-center align-center sendButton" style={{ backgroundColor: messageForm.message ? '#008080' : '#CCCCCC' }} onClick={sendMessage}>
                <img src={send} alt="" />
              </div>
            </div>
          </div>
        </div>

      </div>

      <a className="whatsapp-xs" href="intent://send/+5521972799769#Intent;scheme=smsto;package=com.whatsapp;action=android.intent.action.SENDTO;end">
        <div className="flex column safe-area">
          <div className="flex flex-1 column">

            <SpeechBubbleContainer>
              {messages.map(message => (
                <SpeechBubble type="left" arrow={message.arrow} text={message.text} />
              ))}
            </SpeechBubbleContainer>

          </div>
        </div>
      </a>

    </div>
  )

})

export default Contact