import React from 'react'

import './SpeechBubble.css'

export default function SpeechBubble(props) {

  const { type, arrow, text } = props
  const { className = '' } = props

  return props.type === 'right'
    ? <Right className={className} type={type} arrow={arrow} text={text} />
    : <Left className={className} type={type} arrow={arrow} text={text} />

}

function Left(props) {

  const { arrow, text } = props
  const { className = '' } = props

  return (
    <div className={className} style={{ display: 'flex', flexDirection: 'row', marginTop: 4, marginBottom: 4 }}>
      <div className="wrapper-left">
        <div className={arrow ? 'content-left-arrow' : 'content-left'}>

          <span style={{ display: 'flex', flexDirection: 'row' }}>{text}</span>

        </div>
      </div>
    </div>
  )

}

function Right(props) {

  const { arrow, text } = props
  const { className = '' } = props

  return (
    <div className={className} style={{ display: 'flex', flexDirection: 'row', marginTop: 4, marginBottom: 4 }}>
      <div className="flex-1"></div>

      <div className="flex-5 wrapper-right">
        <div className={arrow ? 'content-right-arrow' : 'content-right'}>

          <span style={{ display: 'flex', flexDirection: 'row' }}>{text}</span>

        </div>
      </div>
    </div>
  )

}