import React from 'react'

export default function SpeechBubbleContainer(props) {

  return (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      {props.children}
    </div>
  )

}