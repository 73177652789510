import React, { Component } from 'react'
import './Home.css'
import './HomeResponsive.css'
import { Parallax, ParallaxLayer } from '@react-spring/parallax'

import Projects from '../Projects'
import DesignThinking from '../DesignThinking'
import Contact from '../Contact'

import title from '../../assets/title-ssf2.png'
import cloud_text from '../../assets/nuvem.png'
import perdido_text from '../../assets/perdido.png'
import space_astronaut from '../../assets/space_astronaut.svg'
import space_cloud from '../../assets/space_cloud.svg'
import space_cloud_gcp from '../../assets/space_cloud_gcp.svg'
import space_cloud_aws from '../../assets/space_cloud_aws.svg'
import space_cloud_ibm from '../../assets/space_cloud_ibm.svg'
import space_spacecraft from '../../assets/space_spacecraft.svg'
import space_stars from '../../assets/space_stars.svg'
import space_ufo from '../../assets/space_ufo.svg'
import space_moon from '../../assets/space_moon.svg'
import space_starman from '../../assets/space_starman.png'
import rio_skyline from '../../assets/rio_skyline.png'
import rio_oswaldocruz from '../../assets/rio_oswaldocruz.png'

class Home extends Component {

  constructor() {
    super()

    this.state = {
      // size: { width: 1920, height: 1080 },

      ocHeight: 0,
      ocSpeed: 0.2,
      ocOffset: 4.5,
      ocTranslateY: 0,
      ocBottomHeight: 0,
      bottomTranslateY: 0,

      titleProjectsOpacity: 0,
      titleDesignOpacity: 0,
      titleContactOpacity: 0,

      showFirstProject: false,
      sentMessage: false
    }

    this.projects = React.createRef();
    this.contact = React.createRef();
  }

  componentDidMount() {
    const { innerWidth, innerHeight } = window
    this.setOcParams(innerWidth, innerHeight)
    this.listeners()
  }

  listeners() {
    window.addEventListener('resize', event => {
      const { currentTarget } = event
      const { innerWidth, innerHeight } = currentTarget

      this.setOcParams(innerWidth, innerHeight)
    })

    setTimeout(() => {
      const intersectionObserver = new IntersectionObserver(entries => {
        if (entries[0].intersectionRatio <= 0) return;

        if (!this.state.sentMessage) {
          this.setState({ sentMessage: true })
          this.contact.current.sendMessages()
        }
      })

      intersectionObserver.observe(document.querySelector('.whatsapp-xs'))
      intersectionObserver.observe(document.querySelector('.phone-container'))
    }, 1000)
  }


  setOcParams(width, height) {
    const orientation = height > width ? 'portrait' : 'landscape'

    if (orientation === 'portrait') {

      if (width <= 399) this.setState({ ocOffset: 4.87, ocSpeed: 0.1 })
      else if (width >= 400 && width <= 449) this.setState({ ocOffset: 4.87, ocSpeed: 0.1 })
      else if (width >= 450 && width <= 499) this.setState({ ocOffset: 4.85, ocSpeed: 0.1 })
      else if (width >= 500 && width <= 549) this.setState({ ocOffset: 4.84, ocSpeed: 0.1 })
      else if (width >= 550 && width <= 599) this.setState({ ocOffset: 4.82, ocSpeed: 0.1 })
      else if (width >= 600 && width <= 699) this.setState({ ocOffset: 4.82, ocSpeed: 0.1 })
      else if (width >= 700 && width <= 799) this.setState({ ocOffset: 4.82, ocSpeed: 0.12 })
      else if (width >= 800 && width <= 899) this.setState({ ocOffset: 4.82, ocSpeed: 0.12 })
      else if (width >= 900 && width <= 999) this.setState({ ocOffset: 4.82, ocSpeed: 0.12 })
      else if (width >= 1000 && width <= 1199) this.setState({ ocOffset: 4.82, ocSpeed: 0.15 })
      else this.setState({ ocOffset: 4.82, ocSpeed: 0.15 })

    } else {

      if (width <= 600) this.setState({ ocOffset: 4.5, ocSpeed: 0.15 })
      else if (width >= 600 && width <= 959) this.setState({ ocOffset: 4.45, ocSpeed: 0.2 })
      else if (width >= 960 && width <= 1279) this.setState({ ocOffset: 4.5, ocSpeed: 0.2 })
      else if (width >= 1280 && width <= 1500) this.setState({ ocOffset: 4.55, ocSpeed: 0.3 })
      else if (width >= 1500) this.setState({ ocOffset: 4.55, ocSpeed: 0.3 })

    }

  }

  calcOpacity(offset, params) {
    const { start, end } = params

    if (offset < start) return 0
    else if (offset > end) return 1
    return (offset - start) / (end - start)
  }

  getOcHeight(windowWidth) {
    const ocFullWidth = 1920
    const ocFullHeight = 981

    return (ocFullHeight * windowWidth) / ocFullWidth
  }

  getOcBottomHeight() {
    const percent = 25

    return (window.innerWidth * percent) / 100
  }

  render() {
    return (
      <Parallax id="container" ref={ref => (this.parallax = ref)} pages={8.05}>
        <ParallaxLayer offset={0} speed={0} style={{ background: `url("${space_stars}")`, backgroundSize: 'cover' }} />
        <ParallaxLayer offset={1} speed={0} style={{ background: `url("${space_stars}")`, backgroundSize: 'cover' }} />
        <ParallaxLayer offset={2} speed={0} style={{ background: `url("${space_stars}")`, backgroundSize: 'cover' }} />
        <ParallaxLayer offset={3} speed={0} style={{ backgroundColor: '#68abd8' }} />
        <ParallaxLayer offset={4} speed={0} style={{ backgroundColor: '#68abd8' }} />
        <ParallaxLayer offset={5} speed={0} style={{ backgroundColor: '#332549' }} />
        <ParallaxLayer offset={6} speed={0} style={{ backgroundColor: '#332549' }} />
        <ParallaxLayer offset={7} speed={0} style={{ backgroundColor: '#332549' }} />

        <ParallaxLayer offset={0.4} speed={0.7} style={{ pointerEvents: 'none' }}>
          <img src={space_moon} alt="" className="moon" />
        </ParallaxLayer>

        <ParallaxLayer offset={0.2} speed={0} style={{ pointerEvents: 'none' }}>
          <div className="brand-container">
            <img src={title} alt="" />
            <h1 style={{ fontFamily: 'Myriad Pro', color: 'white', fontSize: '1em' }}>Denvolvimento de softwares</h1>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={0.7} speed={-0.2} style={{ pointerEvents: 'none' }}>
          <img src={space_ufo} alt="" className="ufo" />
        </ParallaxLayer>

        <ParallaxLayer offset={1.2} speed={0.2} style={{ pointerEvents: 'none' }}>
          <div className="astronaut-container">
            <img src={perdido_text} alt="" className="astronaut-text" />
            <img src={space_astronaut} alt="" className="astronaut" />
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.2} speed={2} style={{ pointerEvents: 'none' }}>
          <img src={space_spacecraft} alt="" className="spacecraft" />
        </ParallaxLayer>

        <ParallaxLayer offset={1.9} speed={0} style={{ pointerEvents: 'none' }}>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', opacity: .7 }}>
            <span className="dont-panic">DON´T</span>
            <span className="dont-panic">PANIC!</span>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1.9} speed={0.2} style={{ pointerEvents: 'none' }}>
          <img src={space_starman} alt="" className="starman" />
        </ParallaxLayer>

        <ParallaxLayer offset={2.5} speed={0.8} style={{ opacity: 0.1 }}>
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '55%' }} />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '10%', marginLeft: '15%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={2.75} speed={0.5} style={{ opacity: 0.1 }}>
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '70%' }} />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '40%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={2.5} speed={0.2} style={{ opacity: 0.2 }}>
          <img src={space_cloud} alt="" style={{ display: 'block', width: '10%', marginLeft: '10%' }} />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={2.9} speed={0.2} style={{}}>
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '60%', opacity: 0.4 }} />
          <img src={cloud_text} alt="" className="cloud-text" />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '10%', marginLeft: '80%', opacity: 0.4 }} />
        </ParallaxLayer>

        <ParallaxLayer offset={3.5} speed={0.8} style={{ opacity: 0.3 }}>
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '7%' }} />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '10%', marginLeft: '15%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={3.7} speed={0.2} style={{ opacity: 0.4 }}>
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '60%' }} />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '25%', marginLeft: '30%' }} />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '10%', marginLeft: '80%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={2.6} speed={0.4} style={{ opacity: 0.6 }}>
          <img src={space_cloud} alt="" style={{ display: 'block', width: '20%', marginLeft: '5%' }} />
          <img src={space_cloud} alt="" style={{ display: 'block', width: '15%', marginLeft: '75%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={3.5} speed={-0.1} style={{ opacity: 0.7 }}>
          <img src={space_cloud_gcp} alt="" style={{ position: 'fixed', width: '20%', left: '10%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={3.5} speed={-0.3} style={{ opacity: 0.7 }}>
          <img src={space_cloud_aws} alt="" style={{ position: 'fixed', width: '20%', left: '50%', transform: "translate(-50%)" }} />
        </ParallaxLayer>

        <ParallaxLayer offset={3.5} speed={-0.1} style={{ opacity: 0.7 }}>
          <img src={space_cloud_ibm} alt="" style={{ position: 'fixed', width: '20%', right: '10%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={4} speed={0} style={{ opacity: 0.7 }} className="flex row align-center justify-center">
          <img src={rio_skyline} alt="" style={{ position: "fixed", width: '120%', bottom: "0" }} />
        </ParallaxLayer>

        <ParallaxLayer offset={this.state.ocOffset} speed={this.state.ocSpeed}>
          <img src={rio_oswaldocruz} alt="" className="oc" style={{ width: '100%' }} />
        </ParallaxLayer>

        <ParallaxLayer offset={5} speed={0}>
          <Projects ref={this.projects} />
        </ParallaxLayer>

        <ParallaxLayer offset={6} speed={0}>
          <DesignThinking />
        </ParallaxLayer>

        <ParallaxLayer offset={7} speed={0}>
          <Contact ref={this.contact} />
        </ParallaxLayer>

        <ParallaxLayer offset={8} speed={0} style={{ background: '#332549'}}>
          <div className="flex row align-end justify-center">
            <span style={{ fontFamily: 'Roboto-Thin', fontSize: 12, color: 'white', marginTop: 8 }}>Copyright © 2014 - 2021 Appics - Todos os direitos reservados</span>
          </div>
        </ParallaxLayer>

      </Parallax>
    )
  }

}

export default Home