import React from 'react'
import { ParallaxLayer } from '@react-spring/parallax'

import meet from '../../assets/meet.png'
import ideia from '../../assets/ideia.png'

import './DesignThinking.css'
import './DesignThinkingResponsive.css'

export default function DesingThinking(props) {

  return (
    <div className="design-thinking">

      <div className="meet-wrapper flex-1">
        <ParallaxLayer offset={3.4} speed={1}>
          <img src={ideia} alt="rocket bulb" className="rocket-bulb" />
        </ParallaxLayer>

        <ParallaxLayer offset={0} speed={0}>
          <img src={meet} alt="meet" className="meet" />
        </ParallaxLayer>
      </div>

      <div className="flex column flex-1 full-vh">

        <div className="flex-1 hide-pt"></div>
        
        <div className="description flex flex-1 column">
          <div className="flex row">
            <span className="page-title">Desing Thinking</span>
          </div>

          <div className="flex row bullet">
            <span>Inovamos juntos;</span>
          </div>

          <div className="flex row bullet">
            <span>De forma prática e colaborativa, ajudamos a extrair, organizar e testar suas idéias. Afim de criar serviços e experiências que as pessoas gostem de usar.</span>
          </div>

          <div className="flex row bullet">
            <span>Transformamos sua equipe em agentes de inovação;</span>
          </div>

          <div className="flex row bullet">
            <span>Realizamos o Design Thinking presencialmente ou virtualmente</span>
          </div>
        </div>

        <div className="flex-1 hide-pt"></div>
        
      </div>

    </div>
  )

}